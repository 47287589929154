<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Clients
              </span>
            </h3>
            <div class="card-toolbar">
              <b-button v-if="csvLoading" variant="secondary" class="font-weight-bolder mr-3" disabled>
                <b-spinner small /> Télécharger en Excel
              </b-button>
              <b-button v-else @click.prevent="exportCsv" variant="secondary" class="font-weight-bolder mr-3">
                <i class="fas fa-file-download mr-4" /> Télécharger en Excel
              </b-button>

              <a
                href="#"
                v-if="hasUserRights([{ section: 'customers', rights: ['create'] }])"
                @click.prevent="newItem"
                class="btn btn-primary font-weight-bolder d-flex flex-row align-items-center"
              >
                <i class="fas fa-user-plus mr-4" /> Nouveau client
              </a>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body py-4">
            <!--begin::Table-->
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isTableLoading"
              :pagination-options="{ enabled: true }"
              :sort-options="{
                enabled: false,
                initialSortBy: serverParams.sort,
              }"
              :rows="rows"
              :columns="columns"
              @on-row-click="toClient"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              styleClass="custom-table clickable"
            >
              <div slot="loadingContent" class="text-center">Chargement...</div>
              <div slot="emptystate" class="text-center">
                Aucun élément à afficher
              </div>
              <template slot-scope="props" slot="table-row">
                <!-- Column: Action -->
                <!-- <span v-if="props.column.field === 'action'">
                  <router-link :to="{ name: 'customer', params: { id: props.row.id } }">
                      <i class="fas fa-pencil-alt text-primary"/>
                  </router-link>
                </span> -->

                <!-- Column: Common -->
                <span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">Afficher </span>
                    <b-form-select
                      v-model="serverParams.perPage"
                      :options="['10', '25', '50', '100']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      entrées par page (Total : {{ props.total }})
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="serverParams.page"
                      :total-rows="props.total"
                      :per-page="serverParams.perPage"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <i class="fas fa-chevron-left"></i>
                      </template>
                      <template #next-text>
                        <i class="fas fa-chevron-right"></i>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Dashboard-->

    <b-modal
      v-model="modal.show"
      :title="modal.title"
      hide-footer
      no-close-on-backdrop
    >
      <div class="d-block">
        <b-form id="modal-form" @submit.prevent="onSubmitItem">
          <b-form-group label="Prénom :">
            <b-form-input
              v-model="modal.item.firstname"
              type="text"
              placeholder="Prénom"
              required
            />
          </b-form-group>
          <b-form-group label="Nom :">
            <b-form-input
              v-model="modal.item.lastname"
              type="text"
              placeholder="Nom"
              required
            />
          </b-form-group>
          <b-form-group label="Email :">
            <b-form-input
              v-model="modal.item.email"
              type="email"
              placeholder="Email"
              required
            />
          </b-form-group>
          <b-form-group label="Téléphone :">
            <b-form-input
              v-model="modal.item.phone"
              type="tel"
              placeholder="Téléphone"
            />
          </b-form-group>
          <b-form-group label="Adresse :">
            <b-form-textarea
              v-model="modal.item.address"
              placeholder="Adresse"
              rows="3"
              max-rows="6"
            />
          </b-form-group>

          <div class="text-right">
            <b-button class="mr-2" @click.prevent="hideModal" variant="light-primary"
              >Annuler</b-button
            >
            <b-button type="submit" variant="primary">Sauvegarder</b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "customers",
  data() {
    return {
      columns: [
        {
          label: "Prénom",
          field: "firstname",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "Nom",
          field: "lastname",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "Email",
          field: "email",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "Téléphone",
          field: "phone",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        {
          label: "Adresse",
          field: "address",
          sortable: true,
          filterOptions: { enabled: true, placeholder: "Rechercher..." },
        },
        // { label: "", field: "action", width: "80px" },
      ],
      rows: [],
      recents: [],
      totalRecords: 0,
      isTableLoading: true,
      csvLoading: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "name",
          type: "asc",
        },
        page: 1,
        perPage: 10,
      },
      modal: {
        show: false,
        title: "Nouveau client",
        item: {
          id: null,
          firstname: null,
          lastname: null,
          email: null,
          phone: null,
          address: null,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["hasUserRights"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Clients", route: { name: "customers" } }]);
  },
  methods: {
    toClient(props) {
      this.$router.push({ name: 'customer', params: { id: props.row.id } });
    },
    hideModal() {
      this.modal.show = false;
    },
    newItem() {
      this.modal.item = {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        address: null,
      };
      this.modal.title = "Nouveau client";
      this.modal.show = true;
    },
    onSubmitItem() {
      ApiService.post(`/customers`, this.modal.item).then(() => {
        this.fetchItemsFromApi();
        this.modal.show = false;
      });
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Generate params for calls to the API
     */
    getApiParams() {
      const tableSearch = {};
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        if (this.columns.find(column => column.field == e)?.filterOptions?.fullMatch) {
          tableSearch[e] = this.serverParams.columnFilters[e];
        } else {
          tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
        }
      });

      return {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
      };
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;
      ApiService.query("/customers", { params: this.getApiParams() })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch(() => {
          this.isTableLoading = false;
        });
    },
    /**
     * Export table to CSV file
     */
    exportCsv() {
      this.csvLoading = true;
      ApiService
        .query("/customers", { params: { ...this.getApiParams(), toCsv: 1 } })
        .then(({ data }) => {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(data);
          anchor.target = '_blank';
          anchor.download = 'clients.csv';
          anchor.click();
          this.csvLoading = false;
        })
        .catch(() => {
          this.csvLoading = false;
        });
    },
  },
};
</script>
